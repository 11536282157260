<script>
import GalleryImageForm from "@/views/menu/gallery/gallery-image/galleryImageForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    galleryService: "galleryService"
  }
})
export default class GalleryImageDetail extends mixins(GalleryImageForm) {
  formId = "detail-gallery-image-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.galleryImages_detail;
  }

  async afterCreate () {
    this.model = await this.galleryService.readGalleryImage(this.$route.params.idParent, this.$route.params.id);
    this.isReady = true;
  }
}
</script>
